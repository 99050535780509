import React from "react"
import { graphql, PageProps } from "gatsby"
import { CasePageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import CaseBanner from "../components/single-case/case-banner"
import CaseContent from "../components/single-case/case-content"
import AuthorCard from "../components/shared/author-card"
import NextCase from "../components/single-case/next-case"

const SingleCase: React.FC<PageProps<CasePageProps>> = ({ data }) => {
  const {
    tagsTitle,
    tags,
    subtitle,
    caseBannerImage,
    imageOutsideAndText,
    dynamicContentCase,
    theAuthor,
    authorSelect,
  } = data?.PAGE_CONTENT?.singleCasesOptions

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <CaseBanner
        uri={data?.PAGE_CONTENT?.uri}
        title={data?.PAGE_METADATA?.title}
        subtitle={subtitle}
        tagsTitle={tagsTitle}
        tags={tags}
        caseBannerImage={caseBannerImage}
      />
      <CaseContent
        imageOutsideAndText={imageOutsideAndText}
        dynamicContent={dynamicContentCase}
      />
      {authorSelect && (
        <AuthorCard theAuthor={theAuthor} author={authorSelect} />
      )}
      <NextCase />
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpCase(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpCase(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      uri
      singleCasesOptions {
        tagsTitle
        tags
        subtitle
        caseBannerImage {
          altText
          sizes
          sourceUrl
          srcSet
        }
        imageOutsideAndText {
          order
          text
          image {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
        dynamicContentCase {
          order
          dynamicContentCases {
            ... on WpCase_Singlecasesoptions_DynamicContentCase_DynamicContentCases_Content {
              text
            }
            ... on WpCase_Singlecasesoptions_DynamicContentCase_DynamicContentCases_Galleries {
              images {
                altText
                sizes
                sourceUrl
                srcSet
              }
            }
            ... on WpCase_Singlecasesoptions_DynamicContentCase_DynamicContentCases_FullWidthImage {
              image {
                altText
                sizes
                sourceUrl
                srcSet
              }
            }
            ... on WpCase_Singlecasesoptions_DynamicContentCase_DynamicContentCases_ImageAndContent {
              imageContent {
                content
                image {
                  altText
                  sizes
                  sourceUrl
                  srcSet
                }
              }
            }
          }
        }
        theAuthor
        authorSelect {
          ... on WpTeam {
            id
            uri
            title
            singleTeam {
              position
            }
            featuredImage {
              node {
                altText
                sizes
                sourceUrl
                srcSet
              }
            }
          }
        }
      }
    }
  }
`

export default SingleCase
