import React from "react"
import "./case-banner.scss"
import { CaseBannerProps } from "../../types/components"
import Img from "../shared/img"
import { CircleArrow } from "../elements/svg-elements"
import { navigate } from "gatsby"
import { scrollToTop } from "../../utils/helpers"

const CaseBanner: React.FC<CaseBannerProps> = ({
  nextBtn,
  tagsTitle,
  tags,
  uri,
  title,
  subtitle,
  caseBannerImage,
}) => {
  function goToNextCase() {
    if (typeof uri === "undefined") return
    scrollToTop()
    navigate(uri)
  }

  return (
    <div className="case-banner" onClick={goToNextCase}>
      <div className="case-banner__wrapper container--xl">
        <div className="case-banner__title">
          <div className="case-banner__title__left tab--width">
            {nextBtn && (
              <button
                onClick={goToNextCase}
                className="case-banner__title__left__next-btn"
              >
                <CircleArrow /> Next Project
              </button>
            )}
            <div className="case-banner__title__left__tags">
              {tagsTitle && (
                <span
                  className="case-banner__title__left__tags__title"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-easing="ease-out"
                >
                  {tagsTitle}
                </span>
              )}
              {tags && (
                <span
                  className="case-banner__title__left__tags__keywords"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-easing="ease-out"
                >
                  {tags}
                </span>
              )}
            </div>
          </div>
          <div
            className={
              nextBtn
                ? "case-banner__title__right content--width hidden"
                : "case-banner__title__right content--width"
            }
          >
            <h1
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-easing="ease-out"
            >
              {title}
            </h1>
            <span className="case-banner__title__right__subtitle">
              {subtitle}
            </span>
          </div>
        </div>
        <div className="case-banner__featured-image">
          <div className="case-banner__featured-image__left tab--width">
            <span />
          </div>
          <div className="case-banner__featured-image__right content--width">
            <Img imgProps={caseBannerImage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseBanner
