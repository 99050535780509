import React from "react"
import "./case-content.scss"
import { CaseContentProps } from "../../types/components"
import Img from "../shared/img"
import {
  DynamicContentCasesProps,
  ImgProps,
} from "../../types/shared-singulars"

const CaseContent: React.FC<CaseContentProps> = ({
  imageOutsideAndText,
  dynamicContent,
}) => {
  return (
    <div className="case-content">
      <div className="case-content__wrapper container--xl">
        {imageOutsideAndText?.image && imageOutsideAndText?.text && (
          <div
            className="case-content__block__iot block"
            style={{ order: imageOutsideAndText?.order }}
          >
            <div className="case-content__block__iot__left">
              <div className="">
                <Img
                  imgProps={imageOutsideAndText?.image}
                  delay={100}
                  className="case-content-img"
                />
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="ease-out"
              className="case-content__block__iot__right"
              dangerouslySetInnerHTML={{ __html: imageOutsideAndText?.text }}
            />
          </div>
        )}
        {dynamicContent?.dynamicContentCases && (
          <div
            className="case-content__block__dcc block"
            style={{ order: dynamicContent?.order }}
          >
            <div className="case-content__block__dcc__left tab--width">
              <span />
            </div>
            <div className="case-content__block__dcc__right content--width">
              {dynamicContent?.dynamicContentCases?.map(
                (section: DynamicContentCasesProps, index: number) => (
                  <div key={index}>
                    {section?.text && (
                      <div
                        data-aos="fade-up"
                        data-aos-delay="100"
                        data-aos-easing="ease-out"
                        className="case-content__block__dcc__right__text-only section"
                        dangerouslySetInnerHTML={{ __html: section?.text }}
                      />
                    )}
                    {section?.imageContent && (
                      <div className="case-content__block__dcc__right__image-content section">
                        <Img
                          imgProps={section?.imageContent?.image}
                          delay={100}
                        />
                        <p
                          data-aos="fade-up"
                          data-aos-delay="150"
                          data-aos-easing="ease-out"
                        >
                          {section?.imageContent?.content}
                        </p>
                      </div>
                    )}
                    {section?.images && (
                      <div className="case-content__block__dcc__right__gallery section">
                        {section?.images?.map(
                          (image: ImgProps, index: number) => (
                            <Img
                              key={index}
                              imgProps={image}
                              delay={(index + 1) * 50}
                            />
                          )
                        )}
                      </div>
                    )}
                    {section?.image && (
                      <div className="case-content__block__dcc__right__full-image section">
                        <Img imgProps={section?.image} delay={100} />
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CaseContent
