import React, { useMemo } from "react"
import "./next-case.scss"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@gatsbyjs/reach-router"
import { useLanguage } from "../../hooks/use-language"
import CaseBanner from "./case-banner"

const NextCase: React.FC = () => {
  const { pathname } = useLocation()
  const { language } = useLanguage()
  const { ALL_CASES } = useStaticQuery(graphql`
    query {
      ALL_CASES: allWpCase {
        nodes {
          id
          uri
          title
          locale {
            locale
          }
          singleCasesOptions {
            tagsTitle
            tags
            subtitle
            caseBannerImage {
              altText
              sizes
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
  `)

  const nextCase = useMemo(() => {
    let currentGroup = ALL_CASES.nodes.filter(
      (g: any) => g.locale.locale === language
    )
    let currentCase = currentGroup.find((c: any) => c.uri === pathname)
    let caseIndex = currentGroup.indexOf(currentCase)
    if (currentGroup[caseIndex + 1]) {
      return currentGroup[caseIndex + 1]
    } else {
      return currentGroup[0]
    }
  }, [pathname, language])

  return (
    <>
      {nextCase && (
        <div className="next-case">
          <CaseBanner
            nextBtn={true}
            uri={nextCase.uri}
            tagsTitle={nextCase.singleCasesOptions.tagsTitle}
            tags={nextCase.singleCasesOptions.tags}
            title={nextCase.title}
            subtitle={nextCase.singleCasesOptions.subtitle}
            caseBannerImage={nextCase.singleCasesOptions.caseBannerImage}
          />
        </div>
      )}
    </>
  )
}

export default NextCase
